import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import TeamsTabbed from "../../components/teams_tabbed"

const Teams = () => {


    return (
        <Layout>
            <Head
                title="NFL Team Tendencies"
                pageDescription="Run vs Pass Ratio, 11 Personnel Usage, Formations, 4th Down Go For It, Time of Possesion, etc"
            />
            <TeamsTabbed
                activeIndex={1}
            />
        </Layout>
    )
}

export default Teams

